/********************************/
/* OVERWRITE DEFAULT THEME HERE */
/********************************/

// Write your custom style. Feel free to split your code to several files

.footer {
  border: none;
}

.btn { // default buttons round
  border-radius: 10rem;
}
.btn-square {
  border-radius: 0;
}

.card-body {
  border-radius: .8rem;
}
