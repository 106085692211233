/**************************/
/* CUSTOM BUBBLE CSS HERE */
/**************************/

.authentified .authentified-hidden {
  display: none !important;
}
.authentified-show {
  display: none !important;
}
.authentified .authentified-show {
  display: inline !important; /*IE*/
  display: initial !important;
}

.form-control {
  color: #555;
  font-weight: 400;
}

.background-gradient {
  background-image: linear-gradient(135deg, #0169c1 0%, #0169c1 10%, transparent 100%);
}

//----------------------------------------------------/
// Text
//----------------------------------------------------/
a {
  color: #014875;

  &:hover,
  &:focus {
    color: #014875;
  }
}

/*----  HOME : INTRO ----*/
.section-title {
  font-size: 2.65rem;
  line-height: 1.3;
  margin-bottom: 1.3rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.text-block {
  max-width: 1200px;
  margin: auto;
  text-align: justify;
}

.description-page {
  padding-bottom: 4rem;
};

.outline-img {
  border : solid 1px #DFDFDF
}

@media (max-width: 1199px) {
  h1, .h1 {
    font-size: 1.8rem;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

h2, .h2 {
  margin-bottom: 1.5rem;
}

.nav-navbar .nav-link {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-block {
  padding-left: 0;
  padding-right: 0;
}

/*----  MEDIA QUERIES NAVBAR  ----*/
@media (max-width: 991.98px) {
  .footer .nav-link {
    padding-bottom: 0.75rem !important; /* ensure min-height 48px for mobile buttons */
    padding-top: 0.75rem !important;
  }
}

/* @media screen and (min-width : 992px) and (max-width : 1092px) {
  nav {
    padding: 0px !important;
    background-color: yellow !important;
  }
}

@media screen and (min-width : 992px) and (max-width : 1060px) {
  nav > div > section > ul:last-child > li > a {
    padding: 2px !important;
  }
}

@media screen and (min-width : 992px) and (max-width : 1021px) {
  nav > div > section > :nth-child(3) > li > a {
    padding: 2px !important;
    background-color: red !important;
  }
}

@media screen and (min-width : 992px) and (max-width : 1001px) {
  nav > div {
    padding: 2px !important;
    background-color: green !important;
  }
} */
.section {
  overflow: hidden;
}

.container-xxl {
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 992px) {
  .container-xxl {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1560px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  .container-xxl-fluid {
    max-width: 100%;
  }
}

.container {
  overflow: hidden;
}

/*----  BREADCRUMB ----*/
.entry-crumbs {
  font-size: .8rem;
  color: #c3c3c3;

  a {
    color: #b2b2b2;
  }
  a:hover {
    color: #6a6a6a;
  }
}


/*----  FOOTER ----*/
.social > a {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  border: 1px solid #fff3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.social > a > i {
  font-size: 1.8em;
}

/*----  SCROLLBAR ----*/
.light-scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
  height: 12px
}
.light-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 6px;

  &:hover {
    background-color: #b5c9e1;
  }
}
.light-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f5f9;
  border-radius: 4px;
}
.verylight-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px
}
.verylight-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
}
.verylight-scrollbar::-webkit-scrollbar-track {
  border-radius: 2px;
}


/* Code below may not be necessary : to clean later */
#client-feedbacks .feedback-box {
  background: #DDD;
  background: rgba(50,50,50,.15);
  padding: 2rem;
}

#client-feedbacks .feedback-box .message {
  color: inherit;
  min-height: 125px;
}

#client-feedbacks .feedback-box .client {
  margin-top: 1rem;
}

.feature-top-title {
  text-align: left;
}

.feature-top-title h5 {
  text-transform: uppercase;
  font-weight: bold;
}

.feature-content .item-top-content-inner, .feature-content .item-add-content {
  background: rgba(0,0,0, 0.8);
  color:white;
}

.feature-content .item-add-content {
  opacity: 1;
}

.feature-content .subdescription {
  color: #eee;
}

.feature-content .item-add-content-inner section {
  color: #eee;
}

.red.selected .service-icon {
  border: 10px solid #e96656;
}
.green.selected .service-icon {
  border: 10px solid #34d293;
}
.blue.selected .service-icon {
  border: 10px solid #3ab0e2;
}
.yellow.selected .service-icon {
  border: 10px solid #f7d861;
}

.pointer {
  cursor: pointer;
}

.nobr {
  white-space: nowrap;
}

.references-list img {
  height: 76px;
  margin-bottom:40px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.references-list img:hover {
  filter: none;
}

.card {
  border-radius:.8rem;
}

.card-button {
  background-color: #FFF;
  border-radius: .3125rem;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
  display: inline-block;
  margin: .1875rem .375rem;
  padding: .375rem .1875rem;
}

.btn-cta {
  padding: 9px 32px 8px;
  text-transform: none;
  font-size: 1em;
  white-space: nowrap;
}

/*************************/
/**** PACKAGES COLORS ****/
/*************************/
$color-free: #ececf7;
$color-team: #278bff;
$color-business: #3cd458;
$color-enterprise: #ebab00;
.bg-free {
  background-color: $color-free;
}
.border-free {
  border-color: $color-free;
}
.btn-free {
  background-color: $color-free;
  border-color: $color-free;
}

.text-team {
  color: $color-team;
}
.bg-team {
  background-color: $color-team;
}
.border-team {
  border-color: $color-team;
}
.btn-team {
  background-color: $color-team;
  border-color: $color-team;
}

.text-business {
  color: $color-business;
}
.bg-business {
  background-color: $color-business;
}
.border-business {
  border-color: $color-business;
}
.btn-business {
  background-color: $color-business;
  border-color: $color-business;
}

.text-enterprise {
  color: $color-enterprise;
}
.bg-enterprise {
  background-color: $color-enterprise;
}
.border-enterprise {
  border-color: $color-enterprise;
}
.btn-outline-enterprise {
  color: $color-enterprise;
  border-color: $color-enterprise;
}
